import React, { useEffect, useRef } from "react"
import styles from "./breezy-openings.scss"

const OpeningsComponent = () => {
  const containerRef = useRef(null)

  useEffect(() => {
    // Create the script element
    const script = document.createElement("script")
    const container = document.createElement("div")
    container.id = "bzOpeningsContainer"

    script.src =
      "https://zeus-jones.breezy.hr/embed/js?inline=true&group_by=none"
    script.async = true

    // Append the script to the containerRef
    if (containerRef.current) {
      containerRef.current.appendChild(container)
      containerRef.current.appendChild(script)
    }

    // Restructure the HTML after the content is populated
    const rearrangeContent = () => {
      const openingsContainer = document.getElementById("feature")
      // Add the 'ready' class to make the container visible
      if (openingsContainer) {
        openingsContainer.classList.add("ready")
      }
    }

    // Set a timeout to wait for the content to load and then rearrange it
    const timeoutId = setTimeout(rearrangeContent, 1000)

    return () => {
      // Cleanup the script when the component unmounts
      if (containerRef.current) {
        containerRef.current.removeChild(script)
      }
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <div className="module">
      <div className={""}>
        <div id={"feature"} className={"feature"}>
          <div ref={containerRef}></div>
        </div>
      </div>
    </div>
  )
}

export default OpeningsComponent
